@import "../../assets/scss/customVariables";

.invoice-matching {
  .common_table {
    margin-bottom: 0px;
  }

  .matching-table-border {
    border: 1px solid #e0e2e7;
  }
  .common_table .dataTable {
    margin-bottom: 0px;
  }

  .inactive-content {
    background-color: white;
  }

  .rounded-borders {
    border: 1px solid #e0e2e7;
    border-radius: 8px;
    padding: 8px 0px;
    max-width: 100%;
    height: 100%;
  }

  .matching-row {
    height: 100%;
  }
  .invoice-info-subtitle {
    color: #858585;
  }

  .sticky-bottom {
    background-color: white;
  }

  .filter-row {
    background-color: #efefef;
    padding: 8px 24px;
  }
  .kloo-alert.ant-alert-success .primary-color {
    color: #001b37 !important;
  }
}

.matching-btn-width {
  width: 30%;
}

.back-icon-button {
  height: 24px !important;
  padding: 0px 15px !important;
  min-height: 24px !important;
}

.info-tag-matching {
  padding: 8px 16px;
  margin: 16px 16px 0px 16px;
  background: $notification-success-background-color;
  border-radius: 4px;
  width: fit-content;
  .icon-style {
    font-size: 20px;
    color: $brdcolSecondary;
  }
}

.invoice-info-box {
  max-height: calc(100% - 40px);
}

.info-list-container {
  border: 2px solid red;
  // max-height: 100%;
}

.item-table-container {
  border: none;
  margin-bottom: 0px !important;
}

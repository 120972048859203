@import "../../../../assets/scss/customVariables";
@import "../../../../assets/scss/mixins";
@import "../../../../assets/scss/imagePreview.scss";

#data_form {
  height: 100%;
}

.form-upload-container {
  display: flex;
  position: relative;
  height: 100%;
}

/* ::-webkit-scrollbar {
    width: 8px;
}
  
::-webkit-scrollbar-track {
    background: rgb(212, 212, 212);
    border-radius: 20px;
}
  
::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
    border-radius: 20px;
} */

.form-upload-container .form-inside-container {
  border-right: 1px solid rgb(223, 223, 223);
  overflow: auto;
  height: auto;
  float: left;
  width: 100%;
  position: relative;
  z-index: 0;
  padding-right: 24px;
}

.form-upload-container .form-inside-container::-webkit-scrollbar {
  width: 8px;
}

.form-upload-container .form-inside-container::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
  border-radius: 20px;
}

.form-upload-container .form-inside-container::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 20px;
}

.form-upload-container .ant-space-vertical {
  width: 100%;
}

.form-upload-container .ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  margin-bottom: 10px;
  vertical-align: top;
  transition: margin-bottom 0.3s 0.017s linear;
}

.paid-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.paid-dropdown h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: normal;
  color: #000000;
  /* font-size: 2rem; */
  margin: 0;
}

.paid-dropdown .ant-btn {
  height: auto;
  border: none;
  padding-right: 0;
}

.paid-edit-btn {
  display: flex;
  justify-content: start;
  margin-top: 8px;
}

.paid-edit-btn .ant-btn {
  /* border-radius: .6rem; */
  /* color: white; */
  /* border: none; */
  width: auto;
  /* height: 2.3rem; */
  /* background: #2712FF; */
  /* padding-left: 0; */
}

.paid-edit-btn .custom-button {
  padding: 5px 10px !important;
  min-height: auto !important;
}

.form-upload-container .ant-picker {
  border: 2px solid #2712ff;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 11px;
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  border-radius: 4px;
  /* font-weight: 500; */
}

.form-upload-container .ant-picker.optional {
  border: 2px solid #d9d9d9;
}

.form-upload-container .ant-picker.optional:hover {
  border: 2px solid #2712ff;
}

.form-upload-container .ant-picker.optional.ant-picker-disabled:hover {
  border: 2px solid #d9d9d9;
}

.form-upload-container .ant-picker.ant-picker-disabled {
  border: 2px solid #d9d9d9;
}

.form-upload-container .ant-picker.ant-picker-disabled input {
  color: #bfbfbf !important;
  background-color: #f5f5f5;
}

.form-upload-container .ant-picker.ant-picker-disabled:hover {
  border: 2px solid #d9d9d9;
}

.form-upload-container .ant-picker-status-error.ant-picker {
  border-color: #ff4d4f;
}

.form-upload-container .title-form {
  font-style: normal;
  line-height: 15px;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.form-upload-container .title-amount {
  margin-left: 15px;
  font-style: normal;
  line-height: 15px;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.form-upload-container .title-rate {
  margin-left: 0;
  font-style: normal;
  line-height: 15px;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
}

.form-upload-container .supplier-container {
  margin-bottom: 10px;
}

.form-upload-container
  .supplier-container
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}

.form-upload-container .supplier-container .ant-form-item {
  margin-bottom: 0;
}

.form-upload-container .form-upload-container .ant-select {
  padding: 0px;
}

.form-upload-container
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 2px solid #d9d9d9;
  border-radius: 4px;
  height: 55.51px;
  align-items: center;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.form-upload-container
  .ant-select:not(.ant-select-customize-input).ant-select-disabled
  .ant-select-selector {
  color: #d9d9d9;
}

.form-upload-container .ant-picker-input > input[disabled] {
  color: #000000;
}

.form-upload-container .country-vat {
  display: block;
  justify-content: space-between;
}

.form-upload-container .country-vat.no-flex {
  display: block;
}

.form-upload-container .country-vat .d-flex {
  display: block;
  justify-content: space-between;
}

.vat-rate .ant-input {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #000000;
}

.vat-rate .ant-input-suffix {
  font-size: 17px;
}

.vat-rate .ant-form-item-explain-error {
  margin-left: 15px;
}

.service-duration .ant-select.ant-select-in-form-item {
  /* width: 106px !important; */
  width: 20%;
  font-size: 16px;
}

.service-duration .service-duration-input-tag {
  border: 2px solid #d9d9d9;
  /* width: 244px !important; */
  width: 100%;
  height: 55.51px;
  border-radius: 4px;
  padding-top: 0px;
  margin-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  font-size: 14px;
  line-height: 17px;
  /* font-weight: 500; */
  color: #000000;
}

.service-duration .service-duration-input-tag:hover {
  border: 2px solid #2712ff;
}

.service-duration .service-duration-input-tag.ant-input-disabled:hover {
  border: 2px solid #d9d9d9;
}

.country-vat .ant-select.ant-select-in-form-item {
  /* width: 286px; */
  width: 100%;
  font-size: 16px;
}

.country-vat .country-vat-vatnumber {
  /* width: 230px; */
  width: 100%;
  font-size: 16px;
  border: 2px solid #2712ff;
  height: 55.51px;
  border-radius: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 11px;
  font-size: 14px;
  line-height: 17px;
  /* font-weight: 500; */
  color: #000000;
}

.country-vat .country-vat-vatnumber.optional {
  border: 2px solid #d9d9d9;
}

.country-vat .country-vat-vatnumber.optional:hover {
  border: 2px solid #2712ff;
}

.country-vat .country-vat-vatnumber.ant-input-disabled:hover {
  border: 2px solid #d9d9d9;
}

.country-vat
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 100%;
}

/* .country-vat .vat-rate-input-tag {
    border: 2px solid #2712FF;
    width: 149px;
    height: 55.51px;
    border-radius: 4px;
    padding-top: 0px;
    margin-left: 15px;
    padding-bottom: 0px;
    padding-right: 11px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #000000;
} */

.country-vat .ant-input-number {
  display: flex;
  align-items: center;
  font-weight: 500;
  /* margin-left: 0; */
  margin-left: 0;
  border: 2px solid #2712ff;
  border-right: none;
  width: auto;
  height: 55.51px;
  border-radius: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #000000;
}

.country-vat .ant-input-number {
  border: 2px solid #d9d9d9;
  border-right: none;
}

.country-vat .ant-input-number.optional {
  border: 2px solid #d9d9d9;
  border-right: none;
}

.vat-rate-input-tag:hover .ant-input-number.optional {
  border: 2px solid #2712ff;
  border-right: none;
}

.vat-rate-input-tag:hover .ant-input-number.optional.ant-input-number-disabled {
  border: 2px solid #d9d9d9;
  border-right: none;
}

.vat-rate-input-tag:hover
  .ant-input-number.optional
  + .ant-input-number-group-addon {
  border: 2px solid #2712ff;
  border-left: none;
}

.country-vat .ant-input-number.ant-input-number-disabled:hover {
  border: 2px solid #d9d9d9;
  border-right: none;
}

.vat-rate-input-tag:hover
  .ant-input-number.ant-input-number-disabled
  + .ant-input-number-group-addon {
  border: 2px solid #d9d9d9;
  border-left: none;
}

.country-vat .ant-input-number:focus,
.ant-input-number-focused {
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}

.country-vat
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number-focused {
  border-color: #ff4d4f;
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}

.country-vat .ant-input-number-group-addon {
  font-size: 19px;
  font-weight: 500;
  border: 2px solid #2712ff;
  padding: 0 11px 0 2px;
  border-radius: 0 4px 4px 0;
  border-left: none;
  background: none;
}

.country-vat .ant-input-number-group-addon {
  border: 2px solid #d9d9d9;
  border-left: none;
}

.country-vat
  .ant-input-number-group-wrapper-status-error
  .ant-input-number-group-addon {
  color: #ff4d4f;
  border-color: #ff4d4f !important;
}

.country-vat .ant-input-number-input {
  /* font-weight: 500; */
}

.amount-tag .ant-form-item-explain-error {
  margin-left: 15px;
}

.form-upload-container .currency-input {
  display: flex;
  justify-content: space-between;
}

.currency-input .ant-select.ant-select-in-form-item {
  width: 70px;
  font-size: 16px;
}

.currency-input .ant-select-selection-item {
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.currency-input .currency-input-tag {
  border: 2px solid #2712ff;
  width: 90%;
  /* width: 280px; */
  height: 55.51px;
  margin-left: 15px;
  border-radius: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 11px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000000;
}

.currency-input .currency-input-tag.ant-input-number-disabled {
  border: 2px solid #d9d9d9;
  color: #000000;
}

.currency-input .currency-input-tag.ant-input-number-disabled input {
  color: #bfbfbf;
  background-color: #f5f5f5;
}

.currency-input-tag .ant-input-number-input-wrap {
  height: 100%;
}

.currency-input-tag .ant-input-number-input-wrap .ant-input-number-input {
  height: 100%;
}

.currency-input .currency-selector .ant-select-selector {
  border: 2px solid #2712ff !important;
}

.currency-input .currency-selector.ant-select-disabled .ant-select-selector {
  border: 2px solid #d9d9d9 !important;
}

.currency-input
  .currency-selector.ant-select-disabled
  .ant-select-selector
  .ant-select-selection-item {
  color: #bfbfbf;
  background-color: #f5f5f5;
}

.service-duration-tag .ant-form-item-explain-error {
  margin-left: 15px;
}

.form-upload-container .ant-collapse {
  border: none;
  background: none;
}

.form-upload-container .ant-collapse > .ant-collapse-item {
  border: none;
}

.form-upload-container .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.form-upload-container
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 12px 16px 12px 0;
  color: #2712ff;
  font-size: 17px;
  font-weight: 600;
  /* width: 380px; */
  width: 100%;
}

.form-upload-container .ant-collapse-content {
  border: none;
}

/* .inside-upload-container .img-viewer {
    width: 97%;
    height: 54rem;
    border: none;
} */

.inside-upload-container .file-preview {
  height: 44rem;
}

.inside-upload-container .pdf-viewer {
  width: 97%;
  height: 54rem;
  border: none;
}

.pay-now-btn .ant-btn.ant-btn-block {
  /* margin-top: 5px; */
  width: 175px;
  /* width: auto; */
  /* height: 48px; */
  /* border-radius: 8px !important; */
  /* border: none; */
  /* font-weight: 600; */
  /* color: #FFFFFF; */
  /* font-size: 12px; */
  /* line-height: 15px; */
  /* background: #1127FF; */
}
.mark-as-paid-btn .ant-btn.ant-btn-block {
  /* margin-top: 5px; */
  width: 175px;
  /* width: auto; */
  /* height: 48px; */
  /* border-radius: 8px !important; */
  /* border: none; */
  /* font-weight: 600; */
  /* color: #FFFFFF; */
  /* font-size: 12px; */
  /* line-height: 15px; */
  /* background: #1127FF; */
}
/* .pay-now-btn,
.mark-as-paid-btn,
.schedule-btn {
  width: 100px !important;
} */
.form-upload-container
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 18px;
}

.form-upload-container .ant-form-item-explain-error {
  font-size: 14px !important;
  margin-bottom: 0;
}

.form-upload-container .upload-container {
  // padding-left: 1.5rem;
  width: 50%;
  overflow-x: auto;
}

.upload-container .inside-upload-container {
  margin-top: 36px;
  min-width: 20rem;
  width: 100%;
  height: auto;
  overflow-x: auto;
}

.upload-container .inside-upload-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.upload-container .inside-upload-container::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
  border-radius: 20px;
}

.upload-container .inside-upload-container::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 20px;
}

.upload-container .ant-upload {
  flex-direction: column;
  height: 90%;
}

.upload-container .ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  line-height: 1.5715;
  display: flex;
  flex-direction: column;
}

.upload-container .ant-upload-list-picture-card-container {
  display: contents;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}

.upload-container .ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin-top: 36px;
}

.upload-container
  .ant-upload-list-picture-card
  .ant-upload-list-item-file
  + .ant-upload-list-item-name {
  position: unset;
  bottom: 10px;
  display: block;
}

.upload-container .ant-upload.ant-upload-select-picture-card {
  width: 296px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background: none;
  border: none;
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: column;
}

.upload-container .close-button-cont {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: end;
}

.close-button-cont .ant-btn {
  border: none;
  box-shadow: none;
  z-index: 99;
}

.form-upload-container .ant-upload.ant-upload-drag {
  background: none;
  border: none;
  height: 12%;
}

.upload-container .ant-upload p {
  display: flex;
  justify-content: center;
}

.upload-container .ant-upload.ant-upload-select {
  margin-top: 36px;
  display: flex;
  cursor: pointer;
}

.upload-container .ant-upload-list-item-info {
  height: 100%;
  transition: background-color 0.3s;
  cursor: pointer;
  /* background-color: rgba(17, 39, 255, 0.05);
    border-radius: 8px; */
}

/* .upload-container .image-preview {
    width: 500px; 
    overflow-y: auto;
    overflow-x: auto;
} */

/* ---------------------Modal Pop up CSS------------------------------- */

.approve-reject-confirm-modal .ant-modal-content {
  height: 212px;
}

.approve-reject-confirm-modal .approve-cancel-modal .confirm-close-button {
  display: flex;
  justify-content: space-between;
}

.approve-reject-confirm-modal .ant-modal-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232325;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.approve-reject-confirm-modal .ant-modal-content .ant-modal-body {
  height: 132px;
  align-items: unset !important;
  justify-content: unset !important;
}

.approve-reject-confirm-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 0;
  /* width: 520px; */
  width: 100%;
  height: 80px;
  background: #f7f7f9;
  flex: none;
  border-top: 0;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.approve-reject-confirm-modal
  .ant-modal-content
  .ant-modal-footer
  .ant-btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  width: 100%;
  height: 48px;
  background: #1127ff;
  border-radius: 8px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.approve-reject-confirm-modal
  .ant-modal-content
  .ant-modal-footer
  .ant-btn-default {
  color: #1127ff;
  font-style: normal;
  padding: 0;
  border: none;
  background: none;
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  line-height: 24px;
  box-shadow: none;
}

.user-modal-btns {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.delete-user-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 11rem; */
  width: 49%;
  height: 3rem;
  background-color: #1127ff;
  border-radius: 0.5rem;
  color: white;
  border: none;
  font-weight: 600;
  padding: 0px;
  cursor: pointer;
}

.go-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 11rem; */
  width: 49%;
  height: 3rem;
  background-color: transparent;
  border-radius: 0.5rem;
  color: #1127ff;
  border: 2px solid #1127ff;
  font-weight: 600;
  padding: 0px;
  margin-left: 2rem;
  cursor: pointer;
}

/* ----------------------------- Image Preview CSS -------------------------------------------- */

.dynamicOption {
  /* width: 365px;  */
  // width: 95%;
}

.dynamicOption.disabledError {
  cursor: not-allowed;
}

/* .css-1fdsijx-ValueContainer{
    min-height: 55.5px !important;
  } */
/* .ant-form-item-control-input-content .css-3iigni-container .css-16xfy0z-control{
    min-height: 55.5px !important;
  } */
/* .ant-form-item-control-input-content .css-b62m3t-container .css-13cymwt-control{
    min-height: 55.5px !important;
  } */
/* .ant-form-item-control-input-content.css-b62m3t-container.css-t3ipsp-control{
    min-height: 55.5px !important;
  } */

/********* responsive css ***********/
@include customMaxWidthBreakpoint(2600px) {
  .form-upload-container .form-inside-container {
    height: 100% !important;
  }

  .form-upload-container .upload-container .inside-upload-container {
    height: 98% !important;
  }
}

.dynamicOption
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  height: 36px !important;
}

.ant-form-item-control-input-content .free-text {
  border: 2px solid #d9d9d9;
  width: 95%;
  /* width: 365px; */
  height: 55.51px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 11px;
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  border-radius: 4px;
  font-weight: 500;
}

.ant-form-item-control-input-content .free-text-required {
  border: 2px solid #2712ff;
  /* width: 365px; */
  width: 95%;
  height: 55.51px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 11px;
  font-size: 14px;
  color: #000000;
  line-height: 17px;
  border-radius: 4px;
  /* font-weight: 500; */
}

.ant-form-item-control-input-content .free-text:hover {
  border: 2px solid #2712ff;
}

/* .ant-form-item-control-input-content .css-b62m3t-container .css-13cymwt-control{
    border: 2px solid #d9d9d9;
} */
/* .ant-form-item-control-input-content .css-b62m3t-container .css-13cymwt-control:hover{
    border: 2px solid #2712FF;
} */
/* .ant-select-selector{
    border: 2px solid #d9d9d9 !important;
}
.ant-select-selector:hover{
    border: 2px solid #2712FF !important;
} */
.addItemBtn:hover {
  color: white;
  background: #1127ff;
}

/* ****************************** */
.requiredBorder .ant-select-selector {
  border: 2px solid #2712ff !important;
}

/* .requiredBorder .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .css-b62m3t-container .css-13cymwt-control{
    border: 2px solid #2712FF !important;
} */
.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.css-1jqq78o-placeholder {
  color: #bfbfbf !important;
  /* font-weight: 500 !important; */
  font-size: 14px !important;
}

.CreatableSelect-main,
#tree-directory-selector {
  cursor: pointer;
}

.optional .ant-select-arrow svg {
  fill: #bfbfbf;
}

/* .ant-form-item-has-success .ant-select-arrow svg {
  fill: #2712ff;
} */

.requiredBorder .ant-select-arrow svg {
  /* fill: #2712ff; */
}

/* .css-1hb7zxy-IndicatorsContainer{
display: none !important;
} */
#dynamic-selector .dynamic-prefix__control .dynamic-prefix__indicators {
  display: none;
}

.dynamic-prefix__menu {
  z-index: 999 !important;
}

.CreatableSelect-main {
  position: relative;
}

.CreatableSelect-arrow {
  position: absolute;
  top: 15px;
  right: 22px;
  z-index: 1;
}

.down-arrow:before {
  content: "";
  position: absolute;
  top: 35px;
  color: #a8a7a7;
  font-size: 21px;
  right: -3px;
  font-weight: 200;
  transform: rotate(0deg);
  height: 20px;
  width: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.3);
}

.IconsRequired:before {
  content: "";
  position: absolute;
  top: 35px;
  color: #a8a7a7;
  font-size: 21px;
  right: -3px;
  font-weight: 200;
  transform: rotate(0deg);
  height: 20px;
  width: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.3);
}

.cancel-icon:before {
  content: "";
  position: absolute;
  top: 35px;
  color: #a8a7a7;
  font-size: 21px;
  right: -3px;
  font-weight: 200;
  transform: rotate(0deg);
  height: 20px;
  width: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.3);
}

.disabled-cancel-icon:before {
  content: "";
  position: absolute;
  top: 35px;
  color: #a8a7a7;
  font-size: 21px;
  right: -3px;
  font-weight: 200;
  transform: rotate(0deg);
  height: 20px;
  width: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 4px solid rgba(0, 0, 0, 0.3);
}

.cancel-icon:hover::before {
  content: "+" !important;
  position: absolute;
  top: 6px;
  background-color: #0d0d0d3b;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 300;
  transform: rotateZ(45deg);
  right: 3px;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  margin-right: 1px;
  text-align: center;
  padding-left: 0.5px;
}

.cancel-icon:hover {
  position: relative;
}

.arrow-hovering:hover::before {
  z-index: 1;
  content: "+";
  position: absolute;
  top: 46px;
  background-color: #0d0d0d3b;
  color: #ffffff;
  font-size: 12px;
  line-height: 11px;
  font-weight: 300;
  right: 13px;
  height: 11px;
  text-align: center;
  transform: rotateZ(45deg);
  padding-right: 0px;
  margin-right: 1px;
  width: 11px;
  border-radius: 50%;
}

.arrow-hovering:hover {
  position: relative;
}

.arrow-hovering:hover .cancel-icon:before {
  z-index: 1;
  content: "+";
  position: absolute;
  top: 31px;
  background-color: rgba(13, 13, 13, 0.231372549);
  color: #ffffff;
  font-size: 12px;
  line-height: 11px;
  font-weight: 300;
  right: -9px;
  height: 11px;
  text-align: center;
  transform: rotateZ(45deg);
  padding-right: 0px;
  margin-right: 1px;
  width: 11px;
  border-radius: 50%;
  border: 0;
}

.tree-select-Directory {
  position: relative;
}

.ant-tree-list {
  position: absolute !important;
  background-color: #ffff;
  width: 96%;
  z-index: 99;
  padding: 13px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: -10px;
}

.ant-tree-icon__customize {
  display: none !important;
}

/* .dynamicOption
    .ant-form-item-row
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .css-b62m3t-container
    .css-1knki5y-control
    .css-1wy0on6
    .css-1xc3v61-indicatorContainer {
    display: none !important;
  }
  .dynamicOption
    .ant-form-item-row
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .css-b62m3t-container
    .css-e88scb-control
    .css-1wy0on6
    .css-15lsz6c-indicatorContainer {
    display: none !important;
  } */

.grayValue
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-tree-select
  .ant-select-selector
  .ant-select-selection-item {
  color: #bfbfbf;
  background-color: #f5f5f5 !important;
  font-size: 14px;
  font-weight: 500;
}

/* ant-select-status-error */
.disabledError
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-status-error {
  border-color: #ff4d4f;
}

.disabledError
  .ant-form-item-row
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-select-status-error
  .ant-select-selector {
  border-color: #ff4d4f !important;
}

.form-upload-container .form-inside-container {
  width: 50%;
}

@include customMinWidthBreakpoint(1024px) {
  .form-upload-container .pay-and-schedule-btn .cancel-payment-btn {
    display: flex;
  }
  .upload-container .inside-upload-container {
    width: 450px;
  }
  .form-upload-container .upload-container {
    // width: auto;
    overflow-x: initial;
  }

  .service-duration .service-duration-input-tag {
    width: 244px !important;
    padding-right: 11px;
    margin-left: 15px;
  }

  .dynamicOption.disabled .ant-form-item-control-input-content {
    cursor: not-allowed;
  }

  .paid-dropdown {
    flex-direction: row;
  }

  .paid-edit-btn {
    justify-content: end;
    margin-top: 0px;
  }

  .paid-dropdown h1 {
    font-size: 30px;
    line-height: 36px;
  }
}

@include md {
  .form-upload-container .country-vat {
    display: flex;
  }

  .form-upload-container .country-vat .d-flex {
    display: flex;
  }

  .country-vat .ant-input-number,
  .form-upload-container .title-rate {
    margin-left: 15px;
  }

  .country-vat .ant-input-number {
    width: 90px;
  }
}

.currency-search .ant-input-affix-wrapper {
  height: auto;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin: 5px 5px;
  width: -webkit-fill-available;
}

.header-mark-paid {
  font-weight: 600;
  font-size: 16px;
}

.title-mark-paid {
  font-weight: 600;
  font-size: 30px;
}

.pay-and-schedule-btn .cancel-payment-btn {
  /* display: flex !important; */
  gap: 15px;
}

.pay-and-schedule-btn .mark-paid-user {
  display: flex;
  gap: 15px;
}
.pay-and-schedule-btn .mark-paid-user .cancel-payment-btn {
  display: unset !important;
  gap: unset !important;
}
.pay-and-schedule-btn .mark-paid-user .schedule-btn .ant-btn.ant-btn-block {
  /* margin-top: 5px; */
  // width: 175px !important;
}

.pay-and-schedule-btn .non-gbp-mark {
  justify-content: center !important;
}
.owner-optional-field .ant-select-selector {
  border-color: #d9d9d9 !important;
}

.ownerSelect.commonSelect .ant-select-selector {
  height: 55.51px !important;
  border-color: #d9d9d9 !important;
}

.action-btn-container {
  width: 365px;
}

.banner-user-description {
  background-color: $notification-success-background-color;
  align-items: center;
  border-radius: 4px;
  padding: 8px 16px;
  width: fit-content;
}

.a.remove-hyper-link,
a.remove-hyper-link:hover {
  cursor: text !important;
  text-decoration: none !important;
}

.pay-and-schedule-btn .create-update-btn-tooltip {
  .ant-btn[disabled] {
    pointer-events: none;
  }
}

.form-upload-container .invoice-drawer-amount-fields-section {
  background-color: $lightgrey;
  border: 1px solid $grey;
  border-radius: 4px;
}

.form-upload-container .invoice-drawer-tracking-categories-section {
  border: 1px solid $grey;
  border-radius: 4px;
  .prefix-icon {
    border-radius: 4px 0px 0px 4px;
    border: 2px solid tint($txt-color, 50%);
    border-right: none !important;
    background-color: #f2f3f4;
  }
}

.inv-upload-cnt {
  height: calc(100vh - 147px);
}

.close-and-delete-buttons {
  top: -18px;
}

.new-item-list-view {
  border: 1px solid $grey;
  &.kloo-ai {
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #0eae88, #1350c7);
    box-shadow: 0px 0px 0px 3px #b0f1e2 !important;
    margin-left: 3px;
  }

  &.line-item-error {
    border: 2px solid $destructive !important;
  }
}

.kloo-ai-description {
  color: $grey2;
}

.ai-alert-loader {
  height: auto;
  padding: 0;
  margin-right: 20px;
  .kloo-alert .ant-alert-content .ant-alert-message {
    font-weight: 500 !important;
  }
  .anticon {
    color: $brdcolSecondary !important;
    font-size: 22px !important;
  }
}

.tax-solution-label {
  color: $sage-card-text !important;
}

@import "../../../assets/scss/customVariables";

.kloo-title-text {
  margin: 0px;

  &.kloo-font {
    &-30 {
      font-size: 30px;
      line-height: 36px;
    }
    &-24 {
      font-size: 24px;
      line-height: 24px;
    }
    &-22 {
      font-size: 22px;
      line-height: 30px;
    }

    &-20 {
      font-size: 20px;
      line-height: 28px;
    }

    &-18 {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &.kloo-text-color-type {
    &-primary {
      color: $txt-color;
    }

    &-secondary {
      color: $brdcolSecondary;
    }

    &-tertiary {
      color: #858585;
    }

    &-white {
      color: $white;
    }

    &-destructive {
      color: $destructive;
    }
  }
}

.kloo-secondary-text {
  margin-bottom: 0px;

  &.kloo-font {
    &-12 {
      font-size: 12px;
      line-height: 18px;
    }
    &-14 {
      font-size: 14px;
      line-height: 21px;
    }
    &-16 {
      font-size: 16px;
      line-height: 24px;
    }
    &-18 {
      font-size: 18px;
      line-height: 28px;
    }
  }

  &.kloo-text-color-type {
    &-primary {
      color: $txt-color;
    }

    &-secondary {
      color: $brdcolSecondary;
    }

    &-tertiary {
      color: #858585;
    }

    &-white {
      color: $white;
    }

    &-destructive {
      color: $destructive;
    }
  }
}

.kloo-body-text {
  &.ant-typography {
    margin: 0px;
  }

  &.kloo-font {
    &-16 {
      font-size: 16px;
      line-height: 24px;
    }
    &-12 {
      font-size: 12px;
      line-height: 21px;
    }
    &-14 {
      font-size: 14px;
      line-height: 24px;
    }
    &-18 {
      font-size: 18px;
      line-height: 24px;
    }
    &-20 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &.kloo-text-color-type {
    &-primary {
      color: $txt-color;
    }

    &-secondary {
      color: $brdcolSecondary;
    }

    &-tertiary {
      color: #858585;
    }

    &-white {
      color: $white;
    }

    &-destructive {
      color: $destructive;
    }
  }
}

.kloo-tertiary-text {
  &.kloo-font {
    &-14 {
      font-size: 14px;
      line-height: 21px;
    }
    &-12 {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

a.kloo-action-link,
a.kloo-link {
  color: $txt-link !important;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

// .App {
//   @for $i from 1 through 6 {
//     h#{7 - $i} {
//       color: $txt-color;
//     }
//   }
// }

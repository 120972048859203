//! please ignore the comments for now as this file is in R&D state
@use "sass:map";
@use "sass:math";
@import "_spacers";

// utility map
$utilities: (
  "vertical-align": (
    property: vertical-align,
    class: vertical-align,
    values: baseline top middle bottom text-bottom text-top,
  ),
  "float": (
    responsive: true,
    property: float,
    class: float,
    values: (
      start: left,
      end: right,
      none: none,
    ),
  ),
  "object-fit": (
    responsive: true,
    property: object-fit,
    class: object-fit,
    values: (
      contain: contain,
      cover: cover,
      fill: fill,
      scale: scale-down,
      none: none,
    ),
  ),
  "opacity": (
    property: opacity,
    class: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
  "overflow": (
    property: overflow,
    class: overflow,
    values: auto hidden visible scroll,
  ),
  "overflow-x": (
    property: overflow-x,
    class: overflow-x,
    values: auto hidden visible scroll,
  ),
  "overflow-y": (
    property: overflow-y,
    class: overflow-y,
    values: auto hidden visible scroll,
  ),
  "display": (
    responsive: true,
    property: display,
    class: d,
    values: inline inline-block block grid table table-row table-cell flex
      inline-flex none,
  ),
  "width": (
    responsive: true,
    property: width,
    class: w,
    values: (
      5: 5%,
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 90%,
      95: 95%,
      100: 100%,
    ),
  ),
  "max-width": (
    property: max-width,
    class: mw,
    values: (
      100: 100%,
    ),
  ),
  "viewport-width": (
    property: width,
    class: vw,
    values: (
      100: 100vw,
    ),
  ),
  "min-viewport-width": (
    property: min-width,
    class: min-vw,
    values: (
      100: 100vw,
    ),
  ),
  "height": (
    responsive: true,
    property: height,
    class: h,
    values: (
      5: 5%,
      10: 10%,
      15: 15%,
      20: 20%,
      25: 25%,
      30: 30%,
      35: 35%,
      40: 40%,
      45: 45%,
      50: 50%,
      55: 55%,
      60: 60%,
      65: 65%,
      70: 70%,
      75: 75%,
      80: 80%,
      85: 85%,
      90: 90%,
      95: 95%,
      100: 100%,
      auto: auto,
    ),
  ),
  "max-height": (
    property: max-height,
    class: mh,
    values: (
      100: 100%,
    ),
  ),
  "viewport-height": (
    property: height,
    class: vh,
    values: (
      100: 100vh,
    ),
  ),
  "min-viewport-height": (
    property: min-height,
    class: min-vh,
    values: (
      100: 100vh,
    ),
  ),
  "max-viewport-height": (
    property: max-height,
    class: max-vh,
    values: (
      100: 100vh,
    ),
  ),
  "flex": (
    responsive: true,
    property: flex,
    class: flex,
    values: (
      fill: 1 1 auto,
      1: 1,
    ),
  ),
  "flex-direction": (
    responsive: true,
    property: flex-direction,
    class: flex,
    values: row column row-reverse column-reverse,
  ),
  "flex-grow": (
    responsive: true,
    property: flex-grow,
    class: flex,
    values: (
      grow-0: 0,
      grow-1: 1,
    ),
  ),
  "flex-shrink": (
    responsive: true,
    property: flex-shrink,
    class: flex,
    values: (
      shrink-0: 0,
      shrink-1: 1,
    ),
  ),
  "flex-wrap": (
    responsive: true,
    property: flex-wrap,
    class: flex,
    values: wrap nowrap wrap-reverse,
  ),
  "justify-content": (
    responsive: true,
    property: justify-content,
    class: justify-content,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      evenly: space-evenly,
    ),
  ),
  "align-items": (
    responsive: true,
    property: align-items,
    class: align-items,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch,
    ),
  ),
  "align-content": (
    responsive: true,
    property: align-content,
    class: align-content,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      stretch: stretch,
    ),
  ),
  "align-self": (
    responsive: true,
    property: align-self,
    class: align-self,
    values: (
      auto: auto,
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch,
    ),
  ),
  "order": (
    responsive: true,
    property: order,
    class: order,
    values: (
      first: -1,
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      last: 6,
    ),
  ),
  "margin": (
    responsive: true,
    property: margin,
    class: m,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "margin-x": (
    responsive: true,
    property: margin-right margin-left,
    class: mx,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "margin-y": (
    responsive: true,
    property: margin-top margin-bottom,
    class: my,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "margin-top": (
    responsive: true,
    property: margin-top,
    class: mt,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "margin-end": (
    responsive: true,
    property: margin-right,
    class: me,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "margin-bottom": (
    responsive: true,
    property: margin-bottom,
    class: mb,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "margin-start": (
    responsive: true,
    property: margin-left,
    class: ms,
    values:
      map-merge(
        $spacers,
        (
          auto: auto,
        )
      ),
  ),
  "padding": (
    responsive: true,
    property: padding,
    class: p,
    values: $spacers,
  ),
  "padding-x": (
    responsive: true,
    property: padding-right padding-left,
    class: px,
    values: $spacers,
  ),
  "padding-y": (
    responsive: true,
    property: padding-top padding-bottom,
    class: py,
    values: $spacers,
  ),
  "padding-top": (
    responsive: true,
    property: padding-top,
    class: pt,
    values: $spacers,
  ),
  "padding-end": (
    responsive: true,
    property: padding-right,
    class: pe,
    values: $spacers,
  ),
  "padding-bottom": (
    responsive: true,
    property: padding-bottom,
    class: pb,
    values: $spacers,
  ),
  "padding-start": (
    responsive: true,
    property: padding-left,
    class: ps,
    values: $spacers,
  ),
  "gap": (
    responsive: true,
    property: gap,
    class: gap,
    values: $spacers,
  ),
  "row-gap": (
    responsive: true,
    property: row-gap,
    class: row-gap,
    values: $spacers,
  ),
  "column-gap": (
    responsive: true,
    property: column-gap,
    class: column-gap,
    values: $spacers,
  ),
  "font-style": (
    property: font-style,
    class: font-style,
    values: italic normal,
  ),
  "font-weight": (
    property: font-weight,
    class: font-weight,
    values: (
      lighter: 100,
      light: 200,
      normal: 300,
      regular: 400,
      semi-bold: 600,
      bold: 700,
      bolder: 800,
    ),
  ),
  "text-align": (
    responsive: true,
    property: text-align,
    class: text-align,
    values: (
      start: left,
      end: right,
      center: center,
    ),
  ),
  "text-decoration": (
    property: text-decoration,
    class: text-decoration,
    values: none underline line-through,
  ),
  "text-transform": (
    property: text-transform,
    class: text,
    values: lowercase uppercase capitalize,
  ),
  "white-space": (
    property: white-space,
    class: text,
    values: (
      wrap: normal,
      nowrap: nowrap,
    ),
  ),
  "word-wrap": (
    property: word-wrap word-break,
    class: text,
    values: (
      break: break-word,
      break-all: break-all,
    ),
  ),
  "text-color": (
    property: color,
    class: text,
    values: $themeAccents,
  ),
  "background-color": (
    property: background-color,
    class: bg,
    values: $themeAccents,
  ),
  "user-select": (
    property: user-select,
    class: user-select,
    values: all auto none,
  ),
  "pointer-events": (
    property: pointer-events,
    class: pe,
    values: none auto,
  ),
  "visibility": (
    property: visibility,
    class: visibility,
    values: (
      visible: visible,
      invisible: hidden,
    ),
  ),
  "position": (
    responsive: true,
    property: position,
    class: position,
    values: (
      absolute: absolute,
      relative: relative,
      fixed: fixed,
      static: static,
      sticky: sticky,
    ),
  ),
);

// looping through utility map
@each $utilityName, $utilityMap in $utilities {
  $ut: map.get(
    $utilities,
    $utilityName
  ); // get individual utility from utilities map
  $responsive: map.get(
    $ut,
    "responsive"
  ); // get the value of responsiveness which decides if the classes are to be generated for breakpoints
  $property: map.get($ut, "property"); // get the property name
  $class: map.get($ut, "class"); // get the class name eg. .text-align
  $values: map.get($ut, "values"); // get the value or value map

  // check if the value is of type map
  @if type-of($values) == "map" {
    @each $classValue, $actualValue in $values {
      .#{$class}-#{$classValue} {
        @each $prop in $property {
          #{$prop}: #{$actualValue} !important;
        }
      }
    }
  } @else {
    @each $value in $values {
      .#{$class}-#{$value} {
        @each $prop in $property {
          #{$prop}: #{$value} !important;
        }
      }
    }
  }
  // if the responsive is set true then execute following loop
  @if $responsive {
    @each $breakpointName, $breakpointValue in $breakpoints {
      @media (min-width: $breakpointValue) {
        @if type-of($values) == "map" {
          @each $classValue, $actualValue in $values {
            .#{$class}-#{$breakpointName}-#{$classValue} {
              @each $prop in $property {
                #{$prop}: #{$actualValue} !important;
              }
            }
          }
        } @else {
          @each $value in $values {
            .#{$class}-#{$breakpointName}-#{$value} {
              @each $prop in $property {
                #{$prop}: #{$value} !important;
              }
            }
          }
        }
      }
    }
  }
}
